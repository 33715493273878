import React, {useEffect, useState} from 'react';

import {useParams} from "react-router-dom";
import SeasonPicker from "./SeasonPicker";
import MatchList from "./MatchList";
import "./Profile.css"
import Header from "./Header";
import url from "../configUtil"
import MainProfile from "./MainProfile";
import SimpleStats from "./SimpleStats";
import EloChart from "./EloChart";


    const Profile = () => {
        let {season, uuid} = useParams();
        const [seasonData, setSeasonData] = useState([])
        const [curSeason, setSeason] = useState(0)
        const [statData, setStatData] = useState([])
        const [matchData, setMatchData] = useState([])
        const [mmrData, setMmrData] = useState([])
        const [page, setPage] = useState(0);
        const [isMore, setIsMore] = useState(true)
        useEffect( () => {
            async function setUpSeasonList() {
                const seasonsRaw = await fetch(url + '/seasonlist')
                const seasons = await seasonsRaw.json()
                await setSeasonData(seasons)
                await setSeason(Number(season))
            }
            setUpSeasonList()
        }, []);

        useEffect( () => {
            async function setUpData() {
                if (curSeason === 0) return
                const statsRaw = await fetch(url + '/stats/' + curSeason + "/" + uuid)
                const stats = await statsRaw.json()
                setStatData(stats)
            }
            setUpData()
        }, [curSeason]);

        useEffect( () => {
            async function setUpData() {
                const mmrStats = await fetch(url + '/player-mmr/' + uuid)
                const stats = await mmrStats.json()
                setMmrData(stats)
            }
            setUpData()
        }, []);

        useEffect( () => {
            async function setUpMatches() {
                if (curSeason === 0) return
                const link = url + '/matches/' + curSeason + "/" + uuid + "?page=" + page
                const matchesRaw = await fetch(link)
                const matches = await matchesRaw.json()
                setIsMore(matches.isMore)
                setMatchData(matches.data)

            }
            setUpMatches()
        }, [curSeason, page]);
        if (statData.message) {
            return (
                <div>
                    <Header/>
                    <SeasonPicker curSeason={season } seasonData={seasonData} setSeason={setSeason} setPage={setPage}/>
                    <div className={"mainCont"}>
                        {statData.message}
                    </div>
                </div>
            )
        }
        if (!statData || statData.length === 0) {
            return (
                <div>
                    <Header/>
                    <SeasonPicker curSeason={season } seasonData={seasonData} setSeason={setSeason} setPage={setPage}/>
                    <div className={"mainCont"}>
                        Stats are loading, try refreshing again if they haven't loaded!
                    </div>
                </div>
            )
        }
        if ((!matchData || matchData.length === 0)) {
            return (
                <div>
                    <Header/>
                    <SeasonPicker curSeason={season } seasonData={seasonData} setSeason={setSeason} setPage={setPage}/>
                    <div className={"mainCont"}>
                        <div className={"stats"}>{statData && statData.length > 0 &&  <UserStats Name={statData[0].Name} Played={statData[0].Played} Wins={statData[0].Wins} Losses={statData[0].Losses} Draws={statData[0].Draws}
                                                            Goals={statData[0].Goals} Owngoals={statData[0].Owngoals} Prefix={statData[0].Prefix} CS={statData[0].Cleansheets} TotalCS={statData[0].Totalcleansheets}
                                                            MMR={statData[0].MMR} Rankedform={statData[0].Rankedform} Assists={statData[0].Assists} Passes={statData[0].Passes} Turnovers={statData[0].Turnovers}
                                                            Shots={statData[0].Shots} Winrate={statData[0].Winrate}/>}</div>
                        <h1>No matches recorded</h1>
                    </div>
                </div>
            )
        }
        const statObjectList =[
            {name:"Played", value: statData[0].Played},
            {name:"Goals", value: statData[0].Goals},
            {name:"Assists", value: statData[0].Assists},
            {name:"Clean sheets", value: statData[0].Totalcleansheets},
            {name:"Passes", value: statData[0].Passes},
            {name:"Turnovers", value: statData[0].Turnovers},
            {name:"Shots on target", value: statData[0].Shots},
            {name:"Saves", value: statData[0].Saves},
            {name:"Own goals", value: statData[0].Owngoals}
        ]

        const detailedObjectList =[
            {name:"Goals per game", value: Math.round((statData[0].Goals / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Assists per game", value: Math.round((statData[0].Assists / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Clean sheets per game", value: Math.round((statData[0].Totalcleansheets / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Passes per game", value: Math.round((statData[0].Passes / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Turnovers per game", value: Math.round((statData[0].Turnovers / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Shots on target per game", value: Math.round((statData[0].Shots / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Saves per game", value: Math.round((statData[0].Saves / statData[0].Played + Number.EPSILON) * 100) / 100},
            {name:"Goal conversion", value: Math.round((statData[0].Goals / statData[0].Shots + Number.EPSILON) * 100) / 100}
        ]
        return (
            <div>
                <Header/>
                <SeasonPicker curSeason={season } seasonData={seasonData} setSeason={setSeason} setPage={setPage}/>
                <div className={"mainCont"}>
                    <div className={"statsMatchContainer"}>
                    <div className ={"profileStats"}>
                        <MainProfile name={statData[0].Name} wins={statData[0].Wins} losses={statData[0].Losses} draws={statData[0].Draws} rank={statData[0].Prefix} mmr={statData[0].MMR}
                                     peakmmr={statData[0].PeakMMR} rp={statData[0].RP} promoForm={statData[0].promoGames} isPromo={statData[0].isOnPromo} uuid={statData[0].UUID}
                                     peakrank={statData[0].PeakPrefix}
                        />
                            <EloChart mmrData={mmrData} />
                        <SimpleStats statList={statObjectList} />
                        <SimpleStats statList={detailedObjectList} />
                    </div>
                    <div className={"matchListContainer"}>
                        <MatchList matchData={matchData}  isMore={isMore} setPage={setPage} page={page} playerID={uuid}/>
                    </div>
                    </div>
                </div>
            </div>
        )

    }

    const UserStats = (props) => {
    let propMap = []
    for (const key in props) {
        propMap.push({propName: key, propValue: props[key]})
    }
        return (
            <div className={"statsRow"}>
                {propMap.map(p => {
                    return (
                        <UserStat name={p.propName} val={p.propValue}></UserStat>
                    )
                })}
            </div>
        )
    }

    const UserStat = ({name, val}) => {
        return (
           <div>
               <div><strong>{name}</strong></div>
               <div>{val}</div>
           </div>
        )
    }


export default Profile;
