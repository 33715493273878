import "./SimpleStats.css";
import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2'; // Correct import
import zoomPlugin from 'chartjs-plugin-zoom';

const EloChart = ({ mmrData }) => {

    const transformedMmrData = mmrData.map((item) => {
        const dateObject = new Date(item.timestamp);
        return {
          timestamp: dateObject.getTime(),
          player_mmr: item.player_mmr,
        };
      });

  ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'MMR Over Time',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy', // Enable zooming on both x and y axes
        },
        pan: {
          enabled: true,
          mode: 'xy', // Enable panning on both x and y axes
        },
      },
      height: 1000
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            week: 'MMM dd, yyyy',
          },
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'MMR',
        },
      },
    },
  };

  const sampleData = [
    { timestamp: 1741098579000, mmr: 2370 },
    { timestamp: 1741098401000, mmr: 2390 },
    { timestamp: 1741098136000, mmr: 2380 },
    { timestamp: 1741097764000, mmr: 2360 },
    { timestamp: 1741097540000, mmr: 2349 },
    { timestamp: 1741097312000, mmr: 2371 },
    { timestamp: 1741097136000, mmr: 2351 },
    { timestamp: 1741065616000, mmr: 2376 },
    { timestamp: 1741065365000, mmr: 2398 },
    { timestamp: 1741065087000, mmr: 2378 },
  ];

  const data = {
    datasets: [
      {
        label: 'MMR',
        data: transformedMmrData.map((item) => ({
          x: item.timestamp,
          y: item.player_mmr,
        })),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        pointRadius: 0,
      },
    ],
  };

  return (
    <div
    className={'outerContainerSimple'}
    style={{ height: '40vh'}} // Fixed width
  >
    <div style={{ width: '100%', height: '100%' }}>
      <Line options={options} data={data} />
    </div>
  </div>
  );
};

export default EloChart;